<template>
  <div class="cash-request-list">
    <error :errors="cashRequestError" />
    <b-overlay :show="formBusy" rounded="sm">
      <b-row>
        <b-col cols="12">
          <portlet>
            <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <b-pagination
                      class="mb-0 mr-2"
                      v-model="cashRequestMeta.current_page"
                      :total-rows="cashRequestMeta.total"
                      :per-page="cashRequestMeta.per_page"
                      @change="onChangePage"
                  />
                  <div class="actions">
                    <b-row class="align-items-center d-none d-sm-flex">
                      <b-col md="9" sm="6" >
                        <b-form-radio-group id="content-model" @change="onChangeFilter" v-model="filter" :options="filterOption" buttons />
                      </b-col>
                      <b-col md="3" sm="3" >
                        <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm ">
                          يېڭىلاش
                        </button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="item in cashRequestList" md="3" :key="item.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! item.user.avatar}" rounded="" height="50" :src="item.user.avatar" alt="image" />
                    <div :class="{'kt-hidden': item.user.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ item.user.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info overflow-hidden">
                    <a class="kt-widget__username">
                      {{ item.user.nickname }}
                    </a>
                    <span class="kt-widget__desc" v-if="item.status === 'reject'">رەت قىلىنغان</span>
                    <span class="kt-widget__desc text-success" v-if="item.status === 'success'"> تەستىقلانغان </span>
                    <span class="kt-widget__desc text-danger" v-if="item.status === 'pending'"> تەستىق كۈتىۋاتىدۇ </span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئىلتىماس قىلغان سوممىسى</span>
                      <span class="kt-widget__data">{{ item.total }} ￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ھېساباتىدىكى قالدۇق سومما</span>
                      <span class="kt-widget__data">{{ item.wallet.total }} ￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئىلتىماس قىلغان ۋاقتى</span>
                      <span class="kt-widget__data" style="direction: ltr">{{ item.created_at }}</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer d-flex justify-content-around">
                  <b-button :to="{name: 'user.details', params:{id: item.user.id}}" type="button" variant="primary" class="btn-upper mr-2"> كىرىم چىقىم تەپسىلاتى </b-button>
                  <b-button v-if="item.status === 'pending'" @click="handleCashRequest(item)" type="button" variant="danger" class="btn-upper ml-2"> تەستىقلاش </b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="cashRequestList.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'user.list'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">ئەزا باشقۇرۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
    <handle-request
      :item="currentItem"
      :state="cashRequestModalState"
      @close="handleCashRequestModalClose"
      @confirm="handleConfirmCashRequest"
    ></handle-request>
  </div>
</template>

<script>
  import Portlet from "../../../../partials/content/Portlet";
  import Error from "../../../../partials/widgets/Error";
  import HandleCashRequestModal from "../../../../components/user/HandleCashRequestModal";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import { mapGetters } from "vuex";
  import walletCashRequestMixin from "../../../../../mixins/walletCashRequestMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, 'handle-request': HandleCashRequestModal },
    mixins: [ walletCashRequestMixin, formBusyMixin ],
    computed: {
      ...mapGetters(['cashRequestList', 'cashRequestMeta', 'cashRequestError'])
    },
    created() {
      this.getCashRequestList();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "list" },
        { title: "نەقلەشتۈرۈش ئىلتىماسى" }
      ]);
    },
    data(){
      return {
        formBusy: false,
        tableFields: [
          { key: "id", label: "id", class: ['d-none', 'd-md-table-cell'] },
          { key: "avatar", label: "باش سۆرىتى", class: ['d-none', 'd-md-table-cell'] },
          { key: "nickname", label: "تور نامى" },
          { key: "total", label: "سوممىسى" },
          { key: "status", label: "ھالىتى", formatter: (value)=>{
              if ( value === 'pending' ){
                return 'تەستىق كۈتىۋاتىدۇ';
              }else if ( value === 'success' ){
                return 'نەقلەشتۈرۈلدى';
              }else if ( value === 'reject' ){
                return 'رەت قىلىندى';
              }
            } },
          { key: "actions", label: "مەشغۇلات" }
        ],
        tableBusy: false,
        page: 1,
        cashRequestModalState: false,
        currentItem: {},
        filterOption: [
          { text: 'ھەممىسى', value: 0 },
          { text: 'تەستىق كۈتىۋاتقانلىرى', value: 1 },
        ],
        filter: 0
      };
    },
    methods: {
      onChangePage(page = 1){
        this.getCashRequestList(this.filter, page);
      },
      setModalState(state = true){
        this.cashRequestModalState = state;
      },
      handleCashRequest(item){
        this.currentItem = item;
        this.$nextTick(()=>{
          this.setModalState();
        })
      },
      handleCashRequestModalClose(){
        this.setModalState(false);
      },
      handleConfirmCashRequest(e){
        this.setModalState(false);
        this.handleConfirmCashRequestApi(e)
          .then(()=>{
            this.getCashRequestList({page: this.page});
          })
      },
      refreshList(){
        this.getCashRequestList(this.filter, 1);
      },
      onChangeFilter(filter){
        this.getCashRequestList(filter, 1);
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
