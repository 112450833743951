
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";
import { GET_WALLET_CASH_REQUEST_LIST } from "../store/types";
import { SET_WALLET_CASH_REQUEST_ERROR } from "../store/modules/walletCashRequest";

export default {
  methods: {
    getCashRequestList(filter = 0, page = 1){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_WALLET_CASH_REQUEST_LIST, {filter, page})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    handleConfirmCashRequestApi(options){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(`/manager/user/wallet/cash-request/${options.id}/comply`, options)
          .then(response=>{
            resolve(response);
            this.$store.commit(SET_WALLET_CASH_REQUEST_ERROR, null );
            this.hideBusy();
          })
          .catch(response=>{
            handleReject(this.$store, response, SET_WALLET_CASH_REQUEST_ERROR);
            this.hideBusy();
            reject(response);
          })
      })
    }
  }
}
