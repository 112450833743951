<template>
  <div class="cash-request">
    <b-modal @hidden="hiddenModal" centered v-model="modalState" size="lg" id="handleCashRequest" hide-footer title="نەقلەشتۈرۈش ئىلتىماسى بىر تەرەپ قىلىش">
      <b-overlay :show="formBusy" rounded="sm">
        <b-form>
          <p class="total-money my-6 text-danger"> {{ item.total }} يۈەن </p>
          <b-row>
            <b-col cols="3">
              <b-form-group id="input-group-status" label="تەستىقلامسىز ؟" label-for="status">
                <b-form-radio-group
                        id="status"
                        v-model="formData.status"
                        :options="[{text: 'تەستىقلاش', value: 'success'},{text: 'رەت قىلىش', value: 'reject'}]"
                        buttons
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group v-if="formData.status === 'reject'" id="input-group-description" label="رەت قىلىش سەۋەبى" label-for="description">
            <b-input-group>
              <b-form-input id="description" v-model="formData.description" placeholder="رەت قىلىش سەۋەبىنى كىرگۈزۈڭ"></b-form-input>
              <b-input-group-append>
                <b-button @click="onConfirm" size="sm" variant="primary">جەزىملەش</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-button @click="onConfirm" v-if="formData.status === 'success'"  id="submit" type="button" variant="primary">
            جەزىملەش
          </b-button>
        </b-form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "HandleCashRequestModal",
    props: {
      state: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default: ()=>{return {};}
      }
    },
    computed: {
      modalState: {
        get(){
          return this.state;
        },
        set(v){
          return v;
        }
      }
    },
    mixins: [ formBusyMixin ],
    data(){
      return {
        formData: {
          description: '',
          status: null
        }
      };
    },
    methods: {
      hiddenModal(event){
        this.$emit('close');
      },
      onConfirm(){
        let formData = Object.assign(this.formData, {id: this.item.id});
        this.$emit('confirm', formData);
      },
    }
  }
</script>

<style scoped>
  .total-money {
    font-size: 32px;
    text-align: center;
  }
</style>
